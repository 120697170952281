export const PAGE_SET_MENU_STATE = 'PAGE_SET_MENU_STATE'
export const PAGE_TOGGLE_MENU_STATE = 'PAGE_TOGGLE_MENU_STATE'

export const setMenuState = (active) => (
  {
    type: PAGE_SET_MENU_STATE,
    data: active,
  }
)

export const toggleMenuState = () => (
  { type: PAGE_TOGGLE_MENU_STATE }
)
