import * as PageActions from './actions'

const initialState = {
  isMenuActive: false,
}

export default function PageReducer (state = initialState, action = {}) {

  switch (action.type) {
    case PageActions.PAGE_SET_MENU_STATE:
      return {
        ...state,
        isMenuActive: action.data,
      }
    case PageActions.PAGE_TOGGLE_MENU_STATE:
      return {
        ...state,
        isMenuActive: !state.isMenuActive
      }
    default:
      return state
  }
}
