import { createStore as reduxCreateStore, combineReducers } from "redux"

import page from './reducers/page'

const reducer = combineReducers({
  page
})

const Store = reduxCreateStore(reducer)
export default Store
